import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Helmet from "react-helmet"
import HubspotForm from "react-hubspot-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RemoveHTML from "../components/removeHtml"
import { Tabbordion, TabPanel, TabContent } from "react-tabbordion"

// popup components
import ContractorBenefitsPopup from "../components/contractorBenefitsPopup"

import Layout from "../components/layout"
import Seo from "../components/seo"

// accordian
const blockElements = {
  animator: "accordion-animator--unchecked",
  content: "accordion-content",
  panel: "accordion-panel",
  label: "accordion-title",
}

const HomeOwnerPage = ({ data }) => {
  const node = data.wpgraphql.page
  const [visible, setVisible] = useState(false)
  const [popType, setPopType] = useState("contractor_benefits")
  const [popData, setPopData] = useState("")

  const showbenefitpopup = (param, type) => event => {
    event.preventDefault()
    setPopData(param)
    setVisible(true)
    setPopType(type)
  }

  const hidebenefitpopup = () => {
    setVisible(false)
  }

  const popup = () => {
    switch (popType) {
      case "contractor_benefits":
        return (
          <ContractorBenefitsPopup
            visiblity={visible}
            handleClose={hidebenefitpopup}
            popData={popData}
          />
        )

      default:
        return ""
    }
  }

  // const { isStaging } = useLiveControl()
  return (
    <Layout>
      <Seo
        title={"Contractor Referral"}
        description={
          "EnerBank's contractor referral program allows you to earn rewards while telling others about EnerBank's leading home improvement loans."
        }
      />
      <Helmet>
        <body className="page-contractor-referral" />
      </Helmet>
      {/* service-area  */}
      <section
        className="banner-area referral-hero"
        id="referral-page-banner-section"
      >
        <div className="background-holder">
          <div className="row align-items-center relative banner-area-text-container">
            <div className="container text-center pb-5">
              <h1 className="text-white">
                Referring a Contractor is a <span>Win-Win!</span>
              </h1>
            </div>
          </div>
          <div className="container pb-3">
            <div className="row">
              <div className="col-md-6">
                <img
                  className="pb-5"
                  src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/hero-graphic.png"
                  alt="referrals win win"
                />
              </div>
              <div className="col-md-6">
                <h2 className="text-white pb-5">How It Works</h2>
                <ol className="fa-ul ordered-list text-white">
                  <li>
                    <span className="fa-li img-icon">
                      <img
                        src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/step1.png"
                        alt="step icon"
                      />
                    </span>{" "}
                    Click the prompt below to fill out the form
                  </li>
                  <li>
                    <span className="fa-li img-icon">
                      <img
                        src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/step2.png"
                        alt="step icon"
                      />
                    </span>
                    A member of our team will reach out to the referred
                    contractor
                  </li>
                  <li>
                    <span className="fa-li img-icon">
                      <img
                        src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/step3.png"
                        alt="step icon "
                      />
                    </span>
                    Referred contractor finishes the sign up process and enjoys
                    the benefits of EnerBank
                  </li>
                </ol>
                <p className="terms-link">
                  <a className="" href="/contractor-referral-program-details/">
                    Terms and Conditions
                  </a>
                </p>
                <h3 className="cta-heading">Click below if you are</h3>
                <div className="col-md-6 link-card">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#existing-contractor"
                    data-target="#existing-contractor"
                  >
                    <div className="card">
                      <div className="card-image">
                        <img
                          src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/contractor-icon.png"
                          alt="contractor"
                        />
                      </div>
                      <div className="card-body">
                        <p>Referring a Contractor to EnerBank</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6 link-card">
                  <a
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#referred-contractor"
                    data-target="#referred-contractor"
                  >
                    <div className="card">
                      <div className="card-image">
                        <img
                          src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/refer-contractor-icon.png"
                          alt="refer-contractor"
                        />
                      </div>
                      <div className="card-body">
                        <p>A Contractor Referred to EnerBank</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tabbordion
          blockElements={blockElements}
          // animateContent={"marginTop"}
          initialPanels={[{ checked: true, index: 1 }]}
          mode={"toggle"}
          className="accordion"
          id="accordion"
          name="accordion"
        >
          <TabPanel>
            {/* <TabLabel className="w-100 text-left btn btn-link">
            
                      </TabLabel> */}
            <TabContent id="existing-contractor" className="form-section">
              <div className="container">
                <div className="row justify-content-center relative">
                  <div className="col-md-12 pb-5">
                    <h2 className="text-center">
                      <span className="eb-green">Referring Contractors:</span>
                      <br />
                      Share the Benefits of EnerBank!
                    </h2>
                  </div>
                  <div className="col-md-6">
                    <div id="refer-form" className="hs-form">
                      <HubspotForm
                        portalId="381510"
                        formId="78f3ff4f-45fb-4b0c-84cb-ee7c1b7060d6"
                        onSubmit={() => this.handleFormSubmit}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 who-why-refer">
                    <h3 className="pb-3 text-blue">Who Should I Refer?</h3>
                    <p>
                      Contractors who work in residential home improvement such
                      as:
                    </p>
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Friends or family members
                      </li>
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Those within your network
                      </li>
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Others who provide services beyond your scope
                      </li>
                    </ul>
                    <h3 className="pb-3 text-blue">
                      Why Should I Refer Contractors?
                    </h3>
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Help contractors in your network grow their business
                      </li>
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Provide a better experience to customers utilizing your
                        network
                      </li>
                      <li>
                        <span className="fa-li eb-green">
                          <FontAwesomeIcon
                            className="fa-solid"
                            icon="circle-check"
                          />
                        </span>
                        Earn a{" "}
                        <span className="eb-green">$500 Amazon gift card</span>{" "}
                        once your referral becomes activated
                      </li>
                    </ul>
                    <div className="gift-card">
                      <img
                        src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/07/amazon-gift-card.png"
                        alt="Amazon gift card"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <hr className="eb-green"></hr>
                    <h2 className="text-center font-weight-bold my-4">
                      What Benefits Should I Share With Other Contractors
                    </h2>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body num1">
                        <h3 className="text-white">Improve Cash Flow</h3>
                        <p>
                          Offering payment options can help improve your cash
                          flow, eliminating the need to juggle funds between
                          projects.
                        </p>
                        {/* <div className="card-number">1</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body num2">
                        <h3 className="text-white">Increase Project Size</h3>
                        <p>
                          Homeowners are more likely to increase the size and
                          scope of their project when they use payment options
                          for home improvement.<sup>*</sup>
                        </p>
                        <p>
                          <small>
                            <sup>*</sup>Based on the Brickyard Study
                            commissioned by EnerBank USA, 2018
                          </small>
                        </p>
                        {/* <div className="card-number">2</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body num3">
                        <h3 className="text-white">Ease and Convenience</h3>
                        <p>
                          Customers enjoy the ease and convenience that
                          contractor financing provides – even when they were
                          planning on paying in cash.<sup>*</sup>
                        </p>
                        <p>
                          <small>
                            <sup>*</sup>Based on the Brickyard Study
                            commissioned by EnerBank USA, 2018
                          </small>
                        </p>
                        {/* <div className="card-number">3</div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center share-benefits">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body num4">
                        <h3 className="text-white">
                          50% of jobs over $5K are Financed
                        </h3>
                        <p>
                          50% of jobs that are over $5000 are financed in some
                          way – meaning you&#39;re leaving money on the table if
                          you don&#39;t offer payment options. <sup>*</sup>
                        </p>
                        <p>
                          <small>
                            <sup>*</sup>Based on the Bain Report commissioned by
                            EnerBank USA, 2017
                          </small>
                        </p>
                        {/* <div className="card-number">4</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body num5">
                        <h3 className="text-white">
                          Over Two Decades of Experience
                        </h3>
                        <p>
                          Regions | EnerBank has over two decades of experience
                          in the home improvement industry and their loans,
                          tools, and features were all built with contractors in
                          mind.<sup>*</sup>
                        </p>

                        {/* <div className="card-number">5</div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 text-center pt-3">
                    <a href="#existing-contractor" className="btn btn-green">
                      Refer Now!
                    </a>
                  </div>
                </div>
              </div>
            </TabContent>
            <TabContent id="referred-contractor" className="form-section">
              <div className="container">
                <div className="row justify-content-center relative">
                  <div className="col-md-12 pb-5">
                    <h2 className="text-center">
                      <span className="text-blue">Referred Contractors:</span>
                      <br />
                      Join the Thousands of Contractors Who Currently Use
                      EnerBank!
                    </h2>
                  </div>
                  <div className="col-md-12 pb-5">
                    <p className="text-center">
                      <strong className="eb-green">
                        Click on each benefit to learn more
                      </strong>
                    </p>
                  </div>
                </div>
                {node.contractorrefferal.contractorOptionsBenefits
                  .benefitOptions && (
                  <div className="contractor-benenfits row">
                    <ul className="col-12 px-3">
                      {node.contractorrefferal.contractorOptionsBenefits.benefitOptions.map(
                        (item, i) => (
                          <li key={item.fieldGroupName + i}>
                            <Link
                              to={"/"}
                              onClick={showbenefitpopup(
                                item,
                                "contractor_benefits"
                              )}
                              data-title={item.benefitOptionTitle}
                              data-content="Advertising payment options is a great way to set you apart from your competitors and produce more leads for your business."
                            >
                              <div className="box-circle icon regions-green content-center">
                                {(() =>
                                  item?.benefitOptionIcon?.caption ? (
                                    <FontAwesomeIcon
                                      className="fa-icon"
                                      icon={RemoveHTML(
                                        item?.benefitOptionIcon?.caption
                                      )}
                                      preserveAspectRatio="none"
                                    />
                                  ) : (
                                    <img
                                      className="img-fluid"
                                      src={item.benefitOptionIcon.sourceUrl}
                                      alt={item.benefitOptionIcon.altText}
                                    />
                                  ))()}
                              </div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.benefitOptionTitle,
                                }}
                              />
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-12">
                    <hr></hr>
                  </div>
                  <div className="col-md-6">
                    <p>
                      With over{" "}
                      <span className="text-blue">
                        <strong>
                          two decades of experience in the home improvement
                          industry
                        </strong>
                      </span>
                      , Regions | EnerBank is a secure and stable option for
                      your contractor financing needs. With loans, tools, and
                      resources built for contractors, payment options from
                      EnerBank are easy to both integrate into your existing
                      processes and offer to your customers.
                    </p>
                    <p>
                      Contractors who offer{" "}
                      <span className="text-blue">
                        <strong>100% of their customers</strong>
                      </span>{" "}
                      a choice of payment options can:
                    </p>
                    <div className="row justify-content-center pymt-options">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/improve-cashflow.png"
                                alt="cash flow"
                              />
                            </div>
                            <p className="text-center">
                              Improve
                              <br />
                              Cash Flow
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/increase-leads.png"
                                alt="increase leads"
                              />
                            </div>
                            <p className="text-center">
                              Increase
                              <br />
                              Leads
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/boost-close-rates.png"
                                alt="boost close rate"
                              />
                            </div>
                            <p className="text-center">
                              Boost
                              <br />
                              Close Rate
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/grow-project-size.png"
                                alt="grow average project size"
                              />
                            </div>
                            <p className="text-center">
                              Grow Average
                              <br />
                              Project Size
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/eliminate-discounting.png"
                                alt="eliminate ddiscounting"
                              />
                            </div>
                            <p className="text-center">
                              Eliminate
                              <br />
                              Discounting
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-body">
                            <div className="card-image">
                              <img
                                src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/08/reduce-cancellations.png"
                                alt="reduce cancellations"
                              />
                            </div>
                            <p className="text-center">
                              Reduce
                              <br />
                              Cancellations
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>
                      Ready to get started? Fill out a form and a member of our
                      team will be in touch soon about the next steps.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div id="referred-form" className="hs-form">
                      <HubspotForm
                        portalId="381510"
                        formId="8f9d8072-c84b-4aca-aacc-96c26c38d06e"
                        onSubmit={() => this.handleFormSubmit}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabContent>
          </TabPanel>
        </Tabbordion>
      </section>
      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body
          className="p-4 mt-5 text-center"
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <Modal.Footer className="border-0">
          <Button variant="primary mb-3 mx-auto" onClick={handleClose}>
            OK
          </Button>
        </Modal.Footer>
      </Modal> */}
      {popup()}
    </Layout>
  )
}

export default HomeOwnerPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        referralform {
          fieldGroupName

          contractorsInfoForm {
            fieldGroupName
            formHeading
            formField {
              fieldGroupName
              fieldName
              fieldTitle
              fieldType
            }
          }

          userInfoForm {
            fieldGroupName
            topHeading
            formField {
              fieldGroupName
              fieldName
              fieldTitle
              fieldType
            }
            footer
          }
        }

        contractorrefferal {
          topBannerSection {
            fieldGroupName
            sectionHeading
            sectionImage {
              sourceUrl
              altText
            }
          }
          contractorOptionsBenefits {
            benefitOptions {
              fieldGroupName
              benefitOptionTitle
              benefitOptionPopupTitle
              benefitOptionPopupContent
              benefitOptionIcon {
                sourceUrl
                altText
                title
                caption
              }
            }
          }
        }
      }
    }
  }
`
